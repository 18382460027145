window.autosizeTextarea = function autosizeTextarea() {
  if (document.querySelector('textarea[data-autosize]')) {
    const autosizeTextareaElements = document.querySelectorAll(
      'textarea[data-autosize]'
    );
    autosizeTextareaElements.forEach((textarea) => {
      const startHeight = textarea.offsetHeight;
      textarea.addEventListener('input', autosize);
      function autosize() {
        const el = this;
        const newHeight = el.scrollHeight;
        if (newHeight > startHeight) {
          el.style.minHeight = `${el.scrollHeight}px`;
        } else {
          el.style.minHeight = startHeight;
        }
      }
    });
  }
};
autosizeTextarea();

function customSelectsLogic() {
  const defaultCustomSelect = document.getElementsByClassName(
    'default-custom-select'
  )[0];
  if (defaultCustomSelect) {
    const defaultCustomSelectArr = document.querySelectorAll(
      '.default-custom-select'
    );

    defaultCustomSelectArr.forEach((customSelect, i) => {
      const zIndexValue = defaultCustomSelectArr.length - i;
      customSelect.style.zIndex = zIndexValue + 10;

      const customSelectPlaceholder = customSelect.querySelector(
        '.default-custom-select__placeholder'
      );
      const customSelectList = customSelect.querySelector(
        '.default-custom-select__list'
      );
      const customSelectPlaceholderTextContent = customSelect.querySelector(
        '.default-custom-select__placeholder'
      ).textContent;
      customSelectPlaceholder.setAttribute(
        'data-original-placeholder',
        customSelectPlaceholderTextContent
      );

      customSelect.addEventListener('click', (e) => {
        if (!customSelect.classList.contains('active')) {
          customSelect.classList.add('active');
          console.log(customSelect);
        } else {
          if (!e.target.classList.contains('default-custom-select__list')) {
            customSelect.classList.remove('active');
          }
        }
      });

      document.addEventListener('click', (event) => {
        if (
          !customSelectList.contains(event.target) &&
          !customSelect.contains(event.target)
        ) {
          customSelect.classList.remove('active');
        }
      });

      const checkboxesSelect = customSelect.querySelectorAll(
        'input[type="checkbox"]'
      );

      function placeholderCheck() {
        const checkedCount = customSelect.querySelectorAll(
          'input[type="checkbox"]:checked'
        ).length;
        console.log(
          customSelect.querySelectorAll('input[type="checkbox"]:checked')
        );

        if (checkedCount === 1) {
          const checkedItem = customSelect.querySelector(
            'input[type="checkbox"]:checked'
          );
          customSelectPlaceholder.textContent =
            checkedItem.nextElementSibling.nextElementSibling.textContent;
        } else {
          customSelectPlaceholder.textContent =
            checkedCount > 0
              ? `${checkedCount} items selected`
              : customSelectPlaceholderTextContent;
        }
      }

      checkboxesSelect.forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
          placeholderCheck();
        });
      });

      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      for (let j = 0; j < checkboxes.length; j++) {
        checkboxes[j].addEventListener('change', () => {
          placeholderCheck();
        });
      }

      placeholderCheck();
    });
  }
}
customSelectsLogic();

const currentJobsSearchMain = document.querySelector(
  '.current-jobs-search__main'
);
if (currentJobsSearchMain) {
  const inputsArr = currentJobsSearchMain.querySelectorAll('.input');
  inputsArr.forEach((input, i) => {
    const zIndexValue = inputsArr.length - i;
    input.style.zIndex = zIndexValue + 10;
  });
}

window.checkAllCheckboxes = function checkAllCheckboxes() {
  const customSelects = document.querySelectorAll('.default-custom-select');

  customSelects.forEach((customSelect) => {
    const customSelectPlaceholder = customSelect.querySelector(
      '.default-custom-select__placeholder'
    );

    function placeholderCheck() {
      const checkedCount = customSelect.querySelectorAll(
        'input[type="checkbox"]:checked'
      ).length;
      if (checkedCount === 1) {
        const checkedItem = customSelect.querySelector(
          'input[type="checkbox"]:checked'
        );
        customSelectPlaceholder.textContent =
          checkedItem.nextElementSibling.nextElementSibling.textContent;
      } else {
        customSelectPlaceholder.textContent =
          checkedCount > 0
            ? `${checkedCount} items selected`
            : customSelectPlaceholder.getAttribute('data-original-placeholder');
      }
    }

    placeholderCheck();
  });
};

checkAllCheckboxes();
