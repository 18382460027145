import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let placementssSwiperWr = document.querySelectorAll('.recent-placements-swiper-wr');
placementssSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination, EffectFade],
      observer: true, // needs to calculate swiper size
      observeParents: true, // needs to calculate swiper size
      slidesPerView: 1,
      spaceBetween: 16,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        500: {
          slidesPerView: 2.2,
          spaceBetween: 16,
        },
        850: {
          slidesPerView: 3.2,
          spaceBetween: 26,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 26,
        }
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth <= 1024 ? false : true,
      },
    });

    swiperObserver(swiper);
  }
});
