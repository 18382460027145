import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

/* Reveal/Hide card hidden content */
const practiceAreasCards = document.querySelectorAll(
  '.section-practice-areas-card'
);

practiceAreasCards.forEach((card, i) => {
  const arrow = card.querySelector('.section-practice-areas-card__arrow');

  card.addEventListener('click', (e) => {
    if (!card.classList.contains('open')) {
      practiceAreasCards.forEach((c) => {
        c.classList.remove('open');
        c.querySelector('.section-practice-areas-card__arrow').classList.remove(
          'active'
        );
      });

      card.classList.add('open');
      arrow.classList.add('active');
    } else {
      card.classList.remove('open');
      arrow.classList.remove('active');
    }
  });

  if (i === 0) {
    /*card.classList.add('open');
    arrow.classList.add('active');*/
  }
});

let columnCount = 4;
let teamsList;

function initTeamsHub() {
  teamsList = document.querySelector('.section-practice-areas__cards');

  if (teamsList) {
    console.log(columnCount);
    addOpenContentBlocks(teamsList, columnCount);

    const itemsArr = teamsList.querySelectorAll('.section-practice-areas-card');

    itemsArr.forEach((item, idx) => {
      item.addEventListener('click', handleClick);

      if (idx === 0) {
        item.click();
      }
    });
  }
}

function addOpenContentBlocks(teamsList, columnsCount) {
  if (teamsList) {
    const itemsArr = teamsList.querySelectorAll('.section-practice-areas-card');

    const rowsCount = Math.ceil(itemsArr.length / columnsCount);

    for (let i = 1; i <= rowsCount; i++) {
      const openContentBlock = document.createElement('div');
      openContentBlock.classList.add('section-practice-areas-open-content');

      const insertPosition =
        i * columnsCount < itemsArr.length
          ? itemsArr[i * columnsCount - 1].nextSibling
          : null;

      if (insertPosition) {
        teamsList.insertBefore(openContentBlock, insertPosition);
      } else {
        teamsList.appendChild(openContentBlock);
      }
    }
  }
}

function handleClick(event) {
  if (document.querySelector('.section-practice-areas-open-content')) {
    const openElArr = document.querySelectorAll(
      '.section-practice-areas-open-content'
    );
    openElArr.forEach((openEl) => {
      openEl.classList.remove('active');
    });
  }

  const clickedItem = event.currentTarget;

  let openFlag = true;

  if (clickedItem.classList.contains('active')) {
    clickedItem.classList.remove('active');
    openFlag = false;

    setTimeout(function () {
      openFlag = true;
    }, 100);
  } else {
    const itemsArr = teamsList.querySelectorAll('.section-practice-areas-card');
    itemsArr.forEach((item) => {
      item.classList.remove('active');
    });

    clickedItem.classList.add('active');
  }

  console.log(clickedItem);

  const contentToCopy = clickedItem
    .querySelector('.section-practice-areas-card-container')
    .cloneNode(true);

  let nextOpenContentBlock = clickedItem.nextElementSibling;
  while (
    nextOpenContentBlock &&
    !nextOpenContentBlock.classList.contains(
      'section-practice-areas-open-content'
    )
  ) {
    nextOpenContentBlock = nextOpenContentBlock.nextElementSibling;
  }

  if (nextOpenContentBlock) {
    nextOpenContentBlock.innerHTML = '';
    nextOpenContentBlock.appendChild(contentToCopy);
    if (openFlag) {
      nextOpenContentBlock.classList.add('active');
      initSwiper(nextOpenContentBlock);
    } else {
      nextOpenContentBlock.classList.remove('active');
    }
  }
}

function initSwiper(contentBlock) {
  console.log(12314);
  let jobsAreasSwiperWr = contentBlock.querySelector('.jobs-areas-swiper-wr');
  if (jobsAreasSwiperWr) {
    let swiperEl = jobsAreasSwiperWr.querySelector('.swiper');
    let nextEl = jobsAreasSwiperWr.querySelector('.swiper-button-next');
    let prevEl = jobsAreasSwiperWr.querySelector('.swiper-button-prev');
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        640: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 26,
          slidesPerView: 2,
        },
        1024: {
          spaceBetween: 16,
          slidesPerView: 3,
        },
        1200: {
          spaceBetween: 26,
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    });
    swiperObserver(swiper);
  }
}

function destroyTeamsHub() {
  if (teamsList) {
    const itemsArr = teamsList.querySelectorAll('.section-practice-areas-card');

    itemsArr.forEach((item) => {
      item.removeEventListener('click', handleClick);
    });

    if (document.querySelector('.section-practice-areas-open-content')) {
      const openElArr = document.querySelectorAll(
        '.section-practice-areas-open-content'
      );
      openElArr.forEach((openEl) => {
        openEl.remove();
      });
    }

    teamsList = null;
  }
}

/*initTeamsHub();*/

function handleResize() {
  if (window.innerWidth <= 551) {
    columnCount = 1;
    destroyTeamsHub();
    initTeamsHub();
  } else if (window.innerWidth <= 951) {
    columnCount = 2;
    destroyTeamsHub();
    initTeamsHub();
  } else {
    columnCount = 4;
    destroyTeamsHub();
    initTeamsHub();
  }
}

window.addEventListener('resize', handleResize);

handleResize();
