import Choices from 'choices.js';

import './utils/vh.utils.js';
import './utils/animate-on-view.utils';
import './utils/swiperOnResize.utils.js';

import './components/header.component.js';

import './components/animations.component.js';

import './components/swiper-home-hero-first.component.js';
import './components/swiper-home-hero-second.component.js';
import './components/swiper-home-areas.component.js';
import './components/swiper-home-team.component.js';
import './components/swiper-home-difference.component.js';
import './components/swiper-home-success.component.js';
import './components/swiper-home-news.component.js';
import './components/swiper-related-jobs.component.js';
import './components/swiper-clients.component.js';
import './components/swiper-recent-placements.component.js';
import './components/swiper-our-vision.component.js';
import './components/swiper-purpose.component.js';

import './components/practiceAreas.component.js';
import './components/team.component.js';
import './components/solutions.component.js';
import './components/jobs.component.js';

import './components/uploadFile.component.js';

import './components/inputs.component.js';

import './components/map.component.js';

import './libs/countUp.lib.js';

/* SELECTS */
const choices = document.querySelectorAll('.js-choice');

/*choices.forEach((choice) => {
  new Choices(choice, {
    searchEnabled: false,
    itemSelectText: '',
  });
});*/

window.choicesArray = [];

window.initChoices = function initChoices(elementsArr) {
  const choicesDOM = document.querySelector(elementsArr);
  if (choicesDOM) {
    const choicesArr = document.querySelectorAll(`${elementsArr}`);
    for (let i = 0; i < choicesArr.length; i++) {
      console.log('init');
      const parentContainer = choicesArr[i].parentNode;
      /*const list = parentContainer.querySelector(".default-select__list");*/

      const choices = new Choices(choicesArr[i], {
        searchEnabled: false,
        itemSelectText: '',
        placeholder: true,
        searchPlaceholderValue: 'Search',
        shouldSort: false,
        classNames: {
          flippedState: '',
        },
      });
      choicesArray.push(choices);

      const choicesMultipleElement = parentContainer.querySelector(
        ".choices[data-type='select-multiple']"
      );

      if (choicesMultipleElement) {
        choicesMultipleElement.addEventListener('click', () => {
          if (parentContainer.querySelector('.is-open')) {
            choices.hideDropdown();
          }
        });
      }

      /* New multiselect logic */
      if (
        choicesArr[i].multiple &&
        choicesArr[i].hasAttribute('data-multiple-list-logic')
      ) {
        let optionName = null;
        let optionValue = null;

        const multiplePlaceholder = parentContainer.querySelector(
          '.choices__list--multiple'
        );

        const list = document.createElement('ul');
        list.className = 'meta-select__list';
        parentContainer.appendChild(list);

        function createListItem(optionName, optionValue) {
          const listItem = document.createElement('li');
          listItem.setAttribute('data-val', optionValue);
          listItem.innerHTML = `${optionName}`;
          listItem.classList.add('meta-select__list-item');
          list.appendChild(listItem);

          listItem.addEventListener('click', () => {
            handleListItemClick(listItem);
          });
        }
        function handleSelectedOptions() {
          list.innerHTML = '';

          const selectedOptions = Array.from(choicesArr[i].selectedOptions);

          if (selectedOptions.length >= 1) {
            list.classList.add('is-visible');
          } else {
            list.classList.remove('is-visible');
          }

          if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
            choices.setChoiceByValue('');
          }

          selectedOptions.forEach(function (option) {
            optionName = option.textContent;
            optionValue = option.value;
            if (optionName !== 'Select') {
              createListItem(optionName, optionValue);
            }
          });

          const listArr = list.querySelectorAll('.meta-select__list-item');
          if (listArr.length === 1) {
            multiplePlaceholder.textContent = optionName;
          } else if (listArr.length >= 2) {
            multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
          } else {
            multiplePlaceholder.textContent = 'Select';
          }
        }

        function handleListItemClick(listItem) {
          const optionValue = listItem.getAttribute('data-val');

          choices.removeActiveItemsByValue(optionValue);
          handleSelectedOptions();
        }

        handleSelectedOptions();

        choicesArr[i].addEventListener('change', function () {
          handleSelectedOptions();
        });

        list.addEventListener('click', function (event) {
          const liElement = event.target.closest('.meta-select__list-item');
          if (liElement) {
            handleListItemClick(liElement);
          }
        });
      }
    }
  }
};

window.initChoices('.js-choice');

window.getChoice = function getChoice(select) {
  let selectEl =
    typeof select === 'object' ? select : document.querySelector(select);
  let choicesArr = window.choicesArray;
  let targetChoice;
  if (choicesArr) {
    window.choicesArray.forEach((choices) => {
      let choicesSelect = choices.passedElement.element;
      if (choicesSelect == selectEl) {
        targetChoice = choices;
      }
    });
  }
  return targetChoice;
};

window.resetChoice = function resetChoice(select) {
  let choice = getChoice(select);
  if (choice) {
    let startValue = choice.config.choices[0].value;
    choice.setChoiceByValue(startValue);
  }
};

/* ALLOW PHONE TYPE ONLY */
const telInputs = document.querySelectorAll('input[type="tel"]');

telInputs.forEach((input) => {
  input.addEventListener('input', function () {
    this.value = this.value.replace(/[^0-9+]/g, '');
  });
});

/* SCROLL DOWN BUTTON */
const scrollBtn = document.querySelector('.btn-scroll');

if (scrollBtn) {
  scrollBtn.addEventListener('click', (e) => {
    console.log('clicked scroll btn');
    e.preventDefault();
    const currentSection = scrollBtn.closest('section');
    const nextSection = currentSection.nextElementSibling;

    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  });
}

/*if (document.querySelector('.current-jobs-search__submit')) {
  document
    .querySelector('.current-jobs-search__submit')
    .addEventListener('click', function (e) {
      e.preventDefault();
      const targetElement = document.querySelector('.current-jobs-search__cards');
      if (targetElement) {
        const targetPosition =
          targetElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        });
      }
    });
}*/

/* Truncate text */
function truncateTitle(selector, maxLines) {
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    const lineHeight = parseInt(window.getComputedStyle(element).lineHeight);
    const maxHeight = lineHeight * maxLines;
    let content = element.textContent;

    element.style.maxHeight = maxHeight + 'px';
    element.style.overflow = 'hidden';
    element.style.display = '-webkit-box';
    element.style.webkitBoxOrient = 'vertical';
    element.style.webkitLineClamp = maxLines;

    if (element.scrollHeight > maxHeight) {
      while (element.scrollHeight > maxHeight) {
        content = content.replace(/\W*\s(\S)*$/, '...');
        element.textContent = content;
        element.style.display = 'block';
        element.scrollHeight;
      }
    }
  });
}

// truncateTitle('.latest-news-card__title', 6);
truncateTitle('.jobs-areas-card-title', 3);

/* REMOVE FILTERS */
