import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let successSwiperWr = document.querySelectorAll('.home-success-swiper-wr');
successSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination, EffectFade],
      observer: true, // needs to calculate swiper size
      observeParents: true, // needs to calculate swiper size
      slidesPerView: 1.045,
      spaceBetween: 26,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        551: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2.3,
        },
        1024: {
          slidesPerView: 3,
        },
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth <= 1024 ? false : true,
      },
    });

    swiperObserver(swiper);
  }
});

const successCards = document.querySelectorAll('.home-success-card');

const setMinTitleHeight = (cards) => {
  let maxHeights = {
    authorName: 0,
    authorPosition: 0,
    authorCompany: 0,
    authorTime: 0,
  };

  cards.forEach((card) => {
    const authorName = card.querySelector(
      '.home-success-card__author.with-name'
    );
    const authorPosition = card.querySelector(
      '.home-success-card__role.with-position'
    );
    const authorCompany = card.querySelector(
      '.home-success-card__role.with-company'
    );
    const authorTime = card.querySelector(
      '.home-success-card__author.with-time'
    );

    if (authorName)
      maxHeights.authorName = Math.max(
        maxHeights.authorName,
        authorName.offsetHeight
      );
    if (authorPosition)
      maxHeights.authorPosition = Math.max(
        maxHeights.authorPosition,
        authorPosition.offsetHeight
      );
    if (authorCompany)
      maxHeights.authorCompany = Math.max(
        maxHeights.authorCompany,
        authorCompany.offsetHeight
      );
    if (authorTime)
      maxHeights.authorTime = Math.max(
        maxHeights.authorTime,
        authorTime.offsetHeight
      );
  });

  cards.forEach((card) => {
    const authorName = card.querySelector(
      '.home-success-card__author.with-name'
    );
    const authorPosition = card.querySelector(
      '.home-success-card__role.with-position'
    );
    const authorCompany = card.querySelector(
      '.home-success-card__role.with-company'
    );
    const authorTime = card.querySelector(
      '.home-success-card__author.with-time'
    );

    if (authorName) authorName.style.minHeight = `${maxHeights.authorName}px`;
    if (authorPosition)
      authorPosition.style.minHeight = `${maxHeights.authorPosition}px`;
    if (authorCompany)
      authorCompany.style.minHeight = `${maxHeights.authorCompany}px`;
    if (authorTime) authorTime.style.minHeight = `${maxHeights.authorTime}px`;
  });
};

setTimeout(() => setMinTitleHeight(successCards), 1000);

window.addEventListener('resize', () => {
  setMinTitleHeight(successCards);
});
