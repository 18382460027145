import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let clientsSwiperWr = document.querySelectorAll('.clients-swiper-wr');
clientsSwiperWr.forEach((el) => {
  if (el) {
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    if (slidesCount <= 6) {
      swiperOnResize('(max-width: 951px)', swiperEl, {
        modules: [Autoplay, Navigation],
        slidesPerView: 'auto',
        spaceBetween: 20,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        },
        loop: true,
        navigation: {
          prevEl: prevEl,
          nextEl: nextEl,
        },
        breakpoints: {
          641: {
            spaceBetween: 0,
          },
        },
      });
    } else {
      let swiper = new Swiper(swiperEl, {
        modules: [Autoplay, Navigation],
        observer: true, //needs to calculate swiper size
        observeParents: true, //needs to calculate swiper size
        slidesPerView: 'auto',
        spaceBetween: 20,
        speed: 800,
        threshold: 10,
        loop: true,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          641: {
            spaceBetween: 0,
          },
          1024: {
            centeredSlides: true,
          },
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        on: {
          afterInit: function () {
            wrapper.scrollWidth >= wrapper.clientWidth
              ? wrapper.classList.remove('justify-center')
              : wrapper.classList.add('justify-center');
          },

          resize: function () {
            wrapper.scrollWidth >= wrapper.clientWidth
              ? wrapper.classList.remove('justify-center')
              : wrapper.classList.add('justify-center');
          },
        },
      });
      swiperObserver(swiper);
    }
  }
});
