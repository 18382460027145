window.collapseTopFunc = function collapseTopFunc() {
  const collapseTop = document.querySelector(
    '.current-jobs-search-add-collapse__top'
  );
  const collapseBottom = document.querySelector(
    '.current-jobs-search-add-collapse__bottom'
  );

  if (collapseTop) {
    collapseTop.addEventListener('click', function () {
      this.classList.toggle('active');
      collapseBottom.classList.toggle('collapsed');

      const svg = this.querySelector('svg');
      svg.style.transform = collapseBottom.classList.contains('collapsed')
        ? 'rotate(0deg)'
        : 'rotate(180deg)';

      const path = svg.querySelector('path');
      path.setAttribute(
        'fill',
        this.classList.contains('active') ? 'var(--cl-blue)' : '#0A0B26'
      );
    });
  }
};
collapseTopFunc();

/*/!* CLEAR INPUTS AFTER REMOVING FILTERS *!/
window.clearFilters = function clearFilters() {
  const filtersContainer = document.querySelector(
    '.current-jobs-search__filters'
  );
  const filtersArr = filtersContainer.querySelectorAll(
    '.current-jobs-search__filter'
  );

  filtersArr.forEach((filterTag) => {
    const filterName = filterTag.querySelector('span').innerText;
    console.log(filterName);

    filterTag.addEventListener('click', () => {});
  });
};*/
