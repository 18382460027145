import { CountUp } from 'countup.js';

/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 **/
const counts = Array.from(document.getElementsByClassName('count-up'));
if (counts) {
  const defaultOptions = {
    separator: '',
    enableScrollSpy: true,
    scrollSpyRunOnce: true,
  };

  let idNumber = 1;

  counts.forEach((count) => {
    const id = `count-up-${idNumber}`;
    const textContent = count.textContent.trim();
    const valueMatch = textContent.match(/^([\d.,]+)([^\d.,]*)$/);
    const value = valueMatch ? parseFloat(valueMatch[1].replace(',', '')) : 0;
    const symbol = valueMatch ? valueMatch[2] : '';

    let optionsFromDataAttr = { ...count.dataset };

    for (const key in optionsFromDataAttr) {
      if (optionsFromDataAttr[key] === '') {
        optionsFromDataAttr[key] = true;
      }
    }

    count.id = id;
    const countUpInstance = new CountUp(
      id,
      value,
      Object.assign({}, defaultOptions, optionsFromDataAttr)
    );

    // Modified printValue function to add gap between number and symbol
    countUpInstance.printValue = function(value) {
      count.innerHTML = value.toFixed(countUpInstance.options.decimalPlaces) + 
                        `<span style="margin-left: 10px;">${symbol}</span>`;
    };

    countUpInstance.start();

    idNumber++;
  });
}
