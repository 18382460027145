import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils';

let newsSwiperWr = document.querySelectorAll('.home-hero-swiper-first-wr');
newsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');

    swiperOnResize('(max-width: 449px)', swiperEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 'auto',
      spaceBetween: 10,
      speed: 800,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth <= 1024 ? false : true,
      },
    });
  } 
});
