import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let jobTypesSwiperWr = document.querySelectorAll('.section-our-vision__slider');

const tabsOurVisionArr = document.querySelectorAll(
  '.section-our-vision__left-text'
);
jobTypesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');

    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination],
      observer: true, // needs to calculate swiper size
      observeParents: true, // needs to calculate swiper size
      slidesPerView: 1,
      spaceBetween: 24,
      speed: 800,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth <= 1024 ? false : true,
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
          allowTouchMove: false,
          autoplay: false,
        },
      },
    });

    if (window.innerWidth < 768) {
      swiperObserver(swiper);
    }

    console.log(tabsOurVisionArr);
    tabsOurVisionArr.forEach((tab, i) => {
      tab.addEventListener('click', () => {
        for (let j = 0; j < tabsOurVisionArr.length; j++) {
          tabsOurVisionArr[j].classList.remove('active');
        }

        tab.classList.add('active');
        swiper.slideTo(i);
      });
    });
  }
});
