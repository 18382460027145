let columnCount = 3;
let teamsList;
let activeCard = null;

function initTeamsHub() {
  teamsList = document.querySelector('.section-our-team__cards');

  if (!teamsList) {
    return;
  }

  console.log(columnCount);
  addOpenContentBlocks(teamsList, columnCount);

  const itemsArr = teamsList.querySelectorAll('.home-team-card');

  itemsArr.forEach((item) => {
    const itemId = item.getAttribute('id');
    item.addEventListener('click', handleClick);

    if (window.location.hash === `#${itemId}`) {
      item.click();
    }
  });
}

function addOpenContentBlocks(teamsList, columnsCount) {
  if (teamsList) {
    const itemsArr = teamsList.querySelectorAll('.home-team-card');
    const rowsCount = Math.ceil(itemsArr.length / columnsCount);

    for (let i = 1; i <= rowsCount; i++) {
      const openContentBlock = document.createElement('div');
      openContentBlock.classList.add('section-our-team-open-content');

      const insertPosition =
        i * columnsCount < itemsArr.length
          ? itemsArr[i * columnsCount - 1].nextSibling
          : null;

      if (insertPosition) {
        teamsList.insertBefore(openContentBlock, insertPosition);
      } else {
        teamsList.appendChild(openContentBlock);
      }
    }
  }
}

function handleClick(event) {
  const clickedItem = event.currentTarget;

  if (window.innerWidth <= 1024) {
    if (clickedItem.classList.contains('active')) {
      return;
    }
  }
  
  let nextOpenContentBlock = clickedItem.nextElementSibling;

  while (
    nextOpenContentBlock &&
    !nextOpenContentBlock.classList.contains('section-our-team-open-content')
  ) {
    nextOpenContentBlock = nextOpenContentBlock.nextElementSibling;
  }

  const currentActiveItem = teamsList.querySelector('.home-team-card.active');
  const currentActiveContent = teamsList.querySelector(
    '.section-our-team-open-content.active'
  );

  if (clickedItem.classList.contains('active')) {
    clickedItem.classList.remove('active');
    activeCard = null;
    if (nextOpenContentBlock) {
      nextOpenContentBlock.classList.remove('active');
      setTimeout(() => {
        nextOpenContentBlock.innerHTML = '';
      }, 400);
    }
  } else {
    const itemsArr = teamsList.querySelectorAll('.home-team-card');
    itemsArr.forEach((item) => {
      item.classList.remove('active');
    });

    clickedItem.classList.add('active');
    activeCard = clickedItem;

    if (currentActiveContent) {
      currentActiveContent.classList.remove('active');
      currentActiveContent.innerHTML = '';
    }

    if (nextOpenContentBlock) {
      const contentToCopy = clickedItem
        .querySelector('.home-team-card-container')
        .cloneNode(true);
      nextOpenContentBlock.innerHTML = '';
      nextOpenContentBlock.appendChild(contentToCopy);

      // Force a reflow
      void nextOpenContentBlock.offsetWidth;

      setTimeout(() => {
        nextOpenContentBlock.classList.add('active');
      }, 400);
    }
  }
}

function destroyTeamsHub() {
  if (teamsList) {
    const itemsArr = teamsList.querySelectorAll('.home-team-card');

    itemsArr.forEach((item) => {
      item.removeEventListener('click', handleClick);
    });

    if (document.querySelector('.section-our-team-open-content')) {
      const openElArr = document.querySelectorAll(
        '.section-our-team-open-content'
      );
      openElArr.forEach((openEl) => {
        openEl.remove();
      });
    }

    teamsList = null;
  }
}

function handleResize() {
  if (window.innerWidth <= 551) {
    columnCount = 1;
  } else if (window.innerWidth <= 1024) {
    columnCount = 2;
  } else {
    columnCount = 3;
  }

  destroyTeamsHub();
  initTeamsHub();
}

document.addEventListener('DOMContentLoaded', () => {
  handleResize();
});

window.addEventListener('resize', handleResize);