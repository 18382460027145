import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let jobTypesSwiperWr = document.querySelectorAll('.home-difference-swiper-wr');
jobTypesSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination, EffectFade],
      observer: true, // needs to calculate swiper size
      observeParents: true, // needs to calculate swiper size
      slidesPerView: 1.045,
      spaceBetween: 16,
      watchSlidesProgress: true,
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      breakpoints: {
        425: {
          slidesPerView: 1.2,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2.4,
          spaceBetween: 26,
        },
        1024: {
          slidesPerView: 3.2,
          spaceBetween: 26,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 26,
        }
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth <= 1024 ? false : true,
      },
      allowTouchMove: true,
    });

    swiperObserver(swiper);
  }
});
