import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let newsSwiperWr = document.querySelectorAll('.home-news-swiper-wr');
newsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination, EffectFade],
      observer: true, // needs to calculate swiper size
      observeParents: true, // needs to calculate swiper size
      slidesPerView: 1,
      spaceBetween: 16,
      breakpoints: {
        551: {
          slidesPerView: 1.5,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 26,
        },
      },  
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth <= 1024 ? false : true,
      },
    });

    swiperObserver(swiper);
  }
});
