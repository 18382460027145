const solutionsAccordion = document.querySelector('.solutions-section__accordion');

if(solutionsAccordion) {
  const solutionsAccordionItemsArr = document.querySelectorAll('.solutions-section__accordion-item');

  solutionsAccordionItemsArr.forEach(item => {
    const itemToggle = item.querySelector('.solutions-section__accordion-item-top');
    const itemHidden = item.querySelector('.solutions-section__accordion-item-hidden');
    const itemText = item.querySelector('.solutions-section__accordion-item-text');

    itemToggle.addEventListener('click', (e) => {
      if(!item.classList.contains('active')) {
        for (let i = 0; i < solutionsAccordionItemsArr.length; i++) {
          solutionsAccordionItemsArr[i].classList.remove('active');
        }

        item.classList.add('active');
        const itemHiddenHeight = itemText.classList + 50;
        itemHidden.style.maxHeight = `${itemHiddenHeight}px`;

      } else {
        item.classList.remove('active');
      }
    })

  })
}